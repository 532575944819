<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {inject, onMounted, ref} from "vue";
import {useUser} from "@/store/user";
import GlobusDropdown from "@/components/common/GlobusInput/GlobusDropdown.vue";
import GlobusCheckbox from "@/components/common/GlobusInput/GlobusCheckbox.vue";
import BlockView from "@/components/common/BlockView.vue";
import { t } from "@/controllerLK/GlobusLocalization";

const userStore = useUser();
const createProjectFormData = inject("createProjectFormData");

const props = {
  isEdit: {
    type: Boolean,
    default: false,
  }
};

onMounted(() => {
  userStore.getOrganizationMembers();

  if (props.isEdit) {
    for (let user of createProjectFormData.value.users) {
      const foundedUser = userStore.organizationMembers.find((orgMember) => {
        return orgMember.userId === user;
      });

      if (foundedUser) {
        addedUserList.value.push(foundedUser);
      }
    }
  }
});

defineProps({
  showUserList: {
    type: Boolean,
    default: false
  },
  isEdit: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["closeModal"]);

const accessList = [
  {
    value: t("access_list_modal.option.read"),
    icon: "eye-black"
  },
  {
    value: t("access_list_modal.option.write"),
    icon: "pen-black"
  }
];

const addedUserList = ref([]);

const toggleUser = (value, user) => {
  if (value) {
    addedUserList.value.push(user);
  } else {
    const index = addedUserList.value.findIndex((item) => item === user);
    addedUserList.value.splice(index, 1);
  }
};

const checkUserToggle = (userId) => {
  return addedUserList.value.some((item) => item.userId === userId);
};

const saveUserList = () => {
  for (let user of addedUserList.value) {
    createProjectFormData.value.users.push(user.userId);
  }
  createProjectFormData.value.users = [...new Set(createProjectFormData.value.users)];
  emit("closeModal"); 
};


</script>

<template>
  <div
    v-if="showUserList"
    class="user-list-modal"
  >
    <div class="header">
      <div class="header__title">
        {{ t('access_list_modal.title') }}
      </div>
      <div class="header__close">
        <SvgIcon
          height="24px"
          name="x"
          width="24px"
          @click="emit('closeModal')"
        />
      </div>
    </div>

    <div
      v-if="userStore.organizationMembers.length > 0"
      class="body scroll"
    >
      <div
        v-for="user in userStore.organizationMembers"
        :key="user"
        class="user"
        @click.self="toggleUser(!checkUserToggle(user.userId), user)"
      >
        <div
          :class="{'user-info_not_active': !checkUserToggle(user.userId)}"
          class="user-info"
        >
          <GlobusCheckbox
            :check-box-value="checkUserToggle(user.userId)"
            @on-change="value => { toggleUser(value, user) }"
          />
          <img
            v-if="user.image"
            class="user-info__image"
            :src="user.image"
            alt=""
            @click.self="toggleUser(!checkUserToggle(user.userId), user)"
          >
          <SvgIcon
            v-else
            class="user-info__image"
            width="20px"
            height="20px"
            name="undefined-photo"
            @click.self="toggleUser(!checkUserToggle(user.userId), user)"
          />
          <div
            :class="{'user-info__name_active': checkUserToggle(user.userId)}"
            class="user-info__name"
            @click.self="toggleUser(!checkUserToggle(user.userId), user)"
          >
            {{ user.lastName || user.firstName ?
              user.lastName + ' ' + user.firstName + ' ' + user?.middleName : user.userId }}
          </div>
        </div>
        <!--TODO: 18.12.24, task 1050. Выбор прав и отображения владельца убрано,пока не появится реализация на беке-->

        <!--        <div-->
        <!--          v-if="userStore.user.userId === user.userId"-->
        <!--          class="user__title"-->
        <!--        >-->
        <!--          <SvgIcon-->
        <!--            height="14px"-->
        <!--            name="user-check"-->
        <!--            width="14px"-->
        <!--          />-->
        <!--          {{ t('access_list_modal.option.owner') }}-->
        <!--        </div>-->
        <!--        <div-->
        <!--          v-else-->
        <!--          class="user__access"-->
        <!--        >-->
        <!--          <GlobusDropdown-->
        <!--            v-if="checkUserToggle(user.userId)"-->
        <!--            :option-list="accessList"-->
        <!--          />-->
        <!--        </div>-->
      </div>
    </div>

    <div
      v-else
      class="body-no-member"
    >
      {{ t('access_list_modal.no_members') }}
    </div>

    <div
      v-if="userStore.organizationMembers.length > 0"
      class="footer"
    >
      <div
        class="footer__save"
        @click="saveUserList()"
      >
        {{ t('access_list_modal.button.save') }}
      </div>
    </div>
  </div>
  <BlockView
    v-if="showUserList"
  />
</template>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

.user-list-modal {
  position: fixed;
  z-index: 2001;
  top: 50%;
  left: 50%;
  display: flex;

  flex-direction: column;
  width: 440px;

  max-height: 450px;
  padding: 20px;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0 0 0 100vmax rgba(0,0,0,.5);

  gap: 20px;

  .header {
    display: flex;
    justify-content: space-between;

    &__title {
      font-size: 14px;
      font-weight: 600;
    }

    &__close {
      cursor: pointer;
      user-select: none;
    }
  }

  .body-no-member {
    text-align: center;
    padding: 30px 20px 30px 20px;
    color: #373D3F;
    background: $background-color;
  }

  .body {
    overflow: auto;
    height: 100%;
    padding: 10px;
    border: 1px solid $globe-passive-color;
    border-radius: 8px;

    .user {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 40px;
      padding: 10px;
      border-radius: 8px;
      gap: 10px;

      &-info {

        display: flex;

        width: 100%;
        &_not_active {
          width: 100%;
        }
        gap: 4px;
        &__image {
          width: 20px;
          min-width: 20px;
          height: 20px;
          min-height: 20px;
          border-radius: 50%;
        }

        &__name {
          display: inline;
          overflow: hidden;
          align-content: center;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;

          &_active {
            color: #1890FF;
          }
        }
      }

      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $globe-text-grey;
        gap: 5px;
      }

      &__access {
        color: $globe-text-grey;
      }
    }

    .user:hover {
      cursor: pointer;
      user-select: none;
      background: $hover-active-color;
    }

    .user_active {
      background: $hover-active-color;
    }
  }

  .footer {
    &__save {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      padding: 6.4px 15px 6.4px 15px;
      cursor: pointer;
      user-select: none;
      color: $button-active-text-color;
      border-radius: 8px;
      background: $button-active-color;
    }
  }
}
</style>