<script setup>

import {inject, ref, watch} from "vue";
import SvgIcon from "@/components/common/svgButton.vue";
import {useProjects} from "@/store/projects";
import BlockView from "@/components/common/BlockView.vue";

const emit = defineEmits(["closeRegionSearch"]);

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false
  }
});

const selectedRegionList = inject("selectedRegionList");

const searchString = ref("");

const isShowDropdown = ref(false);

const projectStore = useProjects();

const createProjectFormData = inject("createProjectFormData");

const selectedRegion = ref(null);

watch(() => searchString.value, (value) => {

  if (value === selectedRegion.value?.name) {
    projectStore.searchRegion(value);
    isShowDropdown.value = false;
  } else {
    if (value.length === 0) {
      isShowDropdown.value = false;
      projectStore.foundedRegionList = [];
    } else {
      projectStore.searchRegion(value);
      isShowDropdown.value = true;
    }
  }

});

const addRegionToList = () => {
  if (selectedRegion.value?.name === searchString.value) {
    if (props.isEdit) {
      projectStore.createMap(createProjectFormData.value.id, selectedRegion.value.id, selectedRegion.value.name);
    }
    selectedRegionList.value.push(selectedRegion.value);
    searchString.value = "";
    projectStore.foundedRegionList = [];
    emit("closeRegionSearch");
  } else {
    isError.value = true; 
  }
};

const isError = ref(false);
</script>

<template>
  <div class="wrapper">
    <div class="globus-combo-box">
      <input
        v-model="searchString"
        class="globus-combo-box__input"
        :class="{'globus-combo-box__input_error': isError}"
        type="text"
        @focusin="isShowDropdown = true"
      >
      <div
        class="globus-combo-box__button"
        :class="{'globus-combo-box__button_active': typeof (selectedRegion?.id) !== 'undefined'}"
        @click="addRegionToList"
      >
        <SvgIcon
          name="arrow"
          width="16px"
          height="16px"
        />
      </div>
    </div>
    <div
      v-if="isShowDropdown && projectStore.foundedRegionList?.length > 0"
      class="globus-combo-box__dropdown scroll"
    >
      <div
        v-for="item in projectStore.foundedRegionList"
        :key="item"
        class="dropdown-item"
        @click="searchString = item.name; isShowDropdown = false; selectedRegion = item"
      >
        {{ item.name }}
      </div>
    </div>
  </div>

  <BlockView
    v-if="isShowDropdown"
    @click="isShowDropdown = false"
  />
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars';
@import '@/assets/styles/classes.scss';

input {
  all: unset;
}

.wrapper {
  position: relative;

  .globus-combo-box {
    display: flex;
    box-sizing: border-box;
    height: 40px;
    border-radius: 8px;

    &__input {
      padding-left: 5px;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      box-sizing: border-box;
      height: 100%;
      color: $globus-background-secondary-text-color;
      border: 1px solid #EEEEEE;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      &_error {
        border: 1px solid red;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 100%;
      cursor: pointer;
      border-top-right-radius: 8px;

      border-bottom-right-radius: 8px;
      background: $blue-dark;

      &_active {
        background: $blue;
      }
    }

    &__dropdown {
      position: absolute;
      z-index: 2000;
      top: 50px;
      display: flex;
      overflow-x: hidden;
      flex-direction: column;
      width: 100%;
      max-height: 200px;
      padding: 6px;
      border-radius: 8px;
      background: $globus-background-secondary-color;
      box-shadow: 1px 2px 10px 0 #131E2726;
      gap: 4px;

      .dropdown-item {
        padding: 10px;
        cursor: pointer;
        user-select: none;
        border-radius: 8px;

        &:hover {
          background-color: $globus-button-secondary-hover-color;
        }
      }

    }
  }
}
</style>