<template>
  <div 
    v-if="store.activeModule.dataName === 'module_sources'"
    class="switch-toggle"
  >
    <button
      class="switch-toggle__button"
      :class="{ 'switch-toggle__button--active': activeOption === '/main' }"
      @click="setActiveOption('/main')"
    >
      Источники
    </button>
    <button
      class="switch-toggle__button"
      :class="{ 'switch-toggle__button--active': activeOption === '/tags' }"
      @click="setActiveOption('/tags')"
    >
      Теги
    </button>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "@/store";
import {useRouter} from "vue-router";

const store = useStore(); 
const router = useRouter();

const activeRoute = computed(() => router.currentRoute.value.fullPath);

const activeOption = ref(activeRoute);

const setActiveOption = (option) => {
  activeOption.value = option;
   router.push(option); 
};
</script>

<style lang="scss" scoped>
.switch-toggle {
  display: flex;
  margin-right: -8PX;

  &__button {
    flex: 1;
    margin-left: 6px;
    padding: 4px 22px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 22px;
    background-color: #f4f4f4;
    cursor: pointer;
    color: #7B87A5;
    transition: all 0.3s ease;

    &--active {
      background-color: #1F9AFF;
      color: #ffffff;
    }
  }
}
</style>
