<template>
  <GlobusHeader v-if="router.currentRoute.value.fullPath !== '/login'" />
  <RouterView />
  <NotificationBlock />
  <GlobalLoader v-if="store.loader" />
  <ProjectHasntModuleDialog v-if="store.isShowProjectNoModuleDialog" />
</template>

<script setup>
import GlobalLoader from "@/components/common/Preloader/GlobalLoader.vue";
import GlobusHeader from "@/components/GlobusHeader.vue";
import NotificationBlock from "@/components/Notification/NotificationBlock.vue";
import { getToken } from "@/composables/auth";
import {useStore} from "@/store";
import {changeShow} from "@/composables/ModuleCommunication";
import {modules} from "@/composables/constans/modules";
import { exit } from "@/composables/functionExit";
import {onMounted} from "vue";
import {useRouter} from "vue-router";
import {personalAreaInitialize} from "@/composables/PersonalAreaInitialize";
import ProjectHasntModuleDialog from "@/components/ProjectHasntModuleDialog.vue";

const store = useStore();
const router = useRouter();

onMounted(() => {

  const storageLanguage = localStorage.getItem("globusLanguage");

  if (storageLanguage === null) {
    store.language = {code: "ru"};
    localStorage.setItem("globusLanguage", "ru");
  } else {
    store.language = {code: storageLanguage};
  }

  window.storeLK.language.code = store.language.code;

  store.loader = true;
  setListener();
});

const setListener = () => {
  window.addEventListener("SetModule", async (event) => {
    const module = modules.find(item => item.dataName === event.detail.module);

    module ? store.activeModule = module : store.activeModule = {name: "Личный кабинет", dataName: "module_lk"};

    store.routing = event.detail.routing;
    store.mainHrefApp = event.detail.path;
    await personalAreaInitialize();
  });

  window.addEventListener("GoToLogin", () => {
    exit();
    changeShow(false);
  });

  window.addEventListener("getToken", async () => {
    const token = await getToken();
    if(token){
      return token;
    } else {
      exit();
      changeShow(false);
    }
  });
};
</script>

<style lang="scss" scoped></style>