import {defineStore} from "pinia";
import axios from "axios";
import { setProject } from "@/composables/ModuleCommunication";
import {useStore} from "@/store/index";
import {useUser} from "@/store/user";
import { t } from "@/controllerLK/GlobusLocalization";

export const useProjects = defineStore({
    id: "projectsStore",
    state: () => ({
        archivedProjects: [],
        projects: [],
        activeProject: {mapFiles: [], modules: []},
        countries: [],
        foundedCountryId: "",
        foundedRegionList: []
    }),
    actions: {

        async getProject(id){
            let result = await axios.get(`/globus/api/Projects/${id}`);
            return result.data;
        },
        async getProjects(isArchived = false){
            await axios.get(`/globus/api/Projects?archival=${isArchived}`).then((response) => {
                isArchived ? this.archivedProjects = response.data : this.projects = response.data;
            }).catch((error) => {
                console.log(error);
            });
        },

        async createProject(data){
            let result;

            await axios.post("/globus/api/Projects", data).then(async (res) => {
                const mainStore = useStore();
                mainStore.createNotify("Успех", t("notification.project_created.message"), "succ");
                await this.getProjects();
                result = res.data;
            }).catch((error) => {
                console.log(error);
            });
            return result;
        },
        async updateProject(data){
            await axios.patch(`/globus/api/Projects/${data.id}`, data).then(async () => {

                const mainStore = useStore();
                const userStore = useUser();
                mainStore.createNotify("Успех", t("notification.save.message"), "succ");
                this.activeProject = await this.getProject(userStore.user.activeProject);
                await this.getProjects();

            }).catch((error) => {
                console.log(error);
            });
        },
        async changeProjectArchive(data){
            await axios.patch(`/globus/api/Projects/${data.id}`, data);

            const mainStore = useStore();
            const userStore = useUser();

            mainStore.createNotify("Успех", t("notification.save.message"), "succ");
            this.activeProject = await this.getProject(userStore.user.activeProject);
            await this.getProjects(!data.isArchive);
        },
        async deleteProject(id){
            await axios.delete(`/globus/api/Projects/${id}`).then(() => {
                const mainStore = useStore();
                mainStore.createNotify("Успех", t("notification.project_delete.message"), "succ");
                this.getProjects();

            }).catch((error) => {
                console.log(error);
            });
        },

        async setActiveProject(id) {
            const userStore = useUser();

            await axios.patch("/globus/api/User/profile", {activeProject: id}).then(async (res) => {
                userStore.activeProject = res.data;
                this.activeProject = this.projects.find(project => project.id === id);
            }).catch((error) => {
                console.log(error);
            });
        },

        clearData() {
            this.projects = [];
            this.activeProject = "";
            setProject(""); // очистка юзера в приложение 
        },

        async searchRegion(value) {
            try {
                const result = await axios.get(`/globus/api/MapFile/locations/${value}`);
                this.foundedRegionList = result.data;
            } catch (e) {
                this.foundedRegionList = [];
                console.log(e);
            }

            return this.foundedRegionList;
        },


        async createMap(projectId, locationId, mapName) {
            await axios.post(`/globus/api/MapFile/projects/${projectId}/maps/${locationId}`,
                {mapName: mapName}).then(async (res) => {

                const projectInStore = this.projects.filter((project) => {
                    return project.id === projectId;
                });

                if (projectId === this.activeProject.id) {
                    setProject(projectInStore[0]);
                    this.activeProject = projectInStore[0];
                }

                projectInStore[0].mapFiles.push(res.data);

            });
        },

        async uploadMap(projectId, map) {
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            let fd = new FormData();
            fd.append("formFile", map);
            fd.append("MapName", map.name);

            await axios.post(`/globus/api/MapFile/projects/${projectId}/maps/upload`, fd, config).then(async (res) => {
                const projectInStore = this.projects.filter((project) => project.id === projectId);
                projectInStore[0].mapFiles.push(res.data);

                if (projectId === this.activeProject.id) {
                    setProject(projectInStore[0]);
                    this.activeProject = projectInStore[0];
                }
            });
        },

        async getProjectMapList(projectId) {
            let result;
            await axios.get(`/globus/api/MapFile/projects/${projectId}/maps`).then((response) => {
                result = response.data;
            });
            return result;
        },

        async deleteProjectMap(projectId, map) {
            await axios.delete(`/globus/api/MapFile/projects/${projectId}/maps/${map.name}`).then(() => {
                const projectInStore = this.projects.filter((project) => project.id === projectId);
                projectInStore[0].mapFiles.splice(projectInStore[0].mapFiles.indexOf(map), 1);

                if (projectId === this.activeProject.id) {
                    setProject(projectInStore[0]);
                    this.activeProject = projectInStore[0];
                }
            }); 
        }
    }
});
