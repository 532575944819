<template>
  <div
    class="globus-header"
    :class="{'lk-page-background': router.currentRoute.value.fullPath === '/lk'}"
  >
    <HeaderProject />
    <HeaderMenu />
    <LogoutDialog
      :show="store.isShowLogoutDialog"
      @close-dialog="store.isShowLogoutDialog = false"
    />
  </div>
</template>

<script setup>
import HeaderProject from "@/components/GlobusHeader/HeaderProject.vue";
import LogoutDialog from "@/components/GlobusHeader/LogoutDialog.vue";
import HeaderMenu from "@/components/GlobusHeader/HeaderMenu.vue";
import {computed, watch} from "vue";
import {useStore} from "@/store";
import {useRouter} from "vue-router";

const store = useStore();
const router = useRouter();

const isShow = computed(() => store.isShowLogoutDialog);

watch(isShow, (value, oldValue, onCleanup) => {
  console.log("logout dialog changes");
  console.log(value, oldValue, onCleanup);
});

</script>

<style lang="scss" scoped>
@import '@/assets/styles/styles.scss';

.globus-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  padding: 20px;
}

.lk-page-background {
  background: #F4FAFF;
}
</style>