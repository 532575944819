<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {useProjects} from "@/store/projects";
import {setProject} from "@/composables/ModuleCommunication";
import {ref} from "vue";
import BlockView from "@/components/common/BlockView.vue";
import { t } from "@/controllerLK/GlobusLocalization";
import {useStore} from "@/store";

const projectStore = useProjects();

const store = useStore();

const props = defineProps({
  project: {
    type: Object,
    required: true,
    default: () => ({
      id: null,
      name: null,
      modules: [],
      users: []
    })
  },
  isArchive: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["editProject", "deleteProject"]);

/* Отображение меню проекта **/
const isShowProjectMenu = ref(false);

/* Сделать выбранный проект активным **/
const setActiveProject = async (project) => {
  if (props.isArchive) return false;
  if (!project.modules.includes(store.activeModule.dataName)) {
    // TODO: Нет макета фигмы, как это отображать
    store.createNotify("",
        t("lk_project_card.notify.unable_active") + " " + t("lk_project_card.modules." + store.activeModule.dataName));
    return false;
  }

  store.loader = true;
  await projectStore.setActiveProject(project.id);
  setProject(project);
  store.loader = false;
};

/* Перенос проекта в архив **/
const changeProjectArchive = async (status) => {
  let project = JSON.parse(JSON.stringify(props.project));

  project.isArchive = status;

  await projectStore.changeProjectArchive(project);
}; 

/* Открыть меню проекта **/
const showProjectMenu = () => {
  isShowProjectMenu.value = true;
};
</script>

<template>
  <div
    class="project"
    :class="{'project-active': project.id === projectStore.activeProject.id}"
    @click.self="setActiveProject(project)"
  >
    <div
      class="project-module"
      @click="setActiveProject(project)"
    >
      <div
        v-for="(module, key) in project.modules.filter((projectModule) => projectModule !== 'module_main')"
        :key="module"
        class="project-module__icon"
        :style="{left: `-${7 * key}px`}"
      >
        <SvgIcon
          class="svg-icon"
          width="29px"
          height="29px"
          :name="module"
        />
      </div>
    </div>

    <div class="project-body">
      <div
        class="project-body__title"
        @click="setActiveProject(project)"
      >
        {{ t('lk_project_card.title') }} {{ project.name }}
      </div>
      <div
        class="project-body__button"
        @click="showProjectMenu()"
      >
        <SvgIcon
          width="14px"
          height="14px"
          :name="`burger-mini`"
        />
      </div>
      <div
        v-if="isShowProjectMenu"
        class="project-body-menu"
      >
        <div
          class="project-body-menu__item"
          @click="emit('editProject', project); isShowProjectMenu = false;"
        >
          <SvgIcon
            width="14px"
            height="14px"
            :name="`edit`"
          />
          {{ t('lk_project_card.options.edit') }}
        </div>

        <div
          v-if="isArchive === false && project.id !== projectStore.activeProject.id"
          class="project-body-menu__item"
          @click="changeProjectArchive(true); isShowProjectMenu = false;"
        >
          <SvgIcon
            width="14px"
            height="14px"
            :name="`archive`"
          />
          {{ t('lk_project_card.options.to_archive') }}
        </div>

        <div
          v-if="isArchive === true"
          class="project-body-menu__item"
          @click="changeProjectArchive(false); isShowProjectMenu = false;"
        >
          <SvgIcon
            width="14px"
            height="14px"
            :name="`archive`"
          />
          {{ t('lk_project_card.options.from_archive') }}
        </div>

        <div
          class="project-body-menu__item"
          @click="emit('deleteProject', project); isShowProjectMenu = false;"
        >
          <SvgIcon
            width="14px"
            height="14px"
            :name="`delete`"
          />
          {{ t('lk_project_card.options.delete') }}
        </div>
      </div>
    </div>
    <div
      class="project__member"
      @click.self="setActiveProject(project)"
    >
      {{ t('lk_project_card.members') }}: {{ project.users.length }}
    </div>
  </div>

  <BlockView
    v-if="isShowProjectMenu"
    @click="isShowProjectMenu = false"
  />
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars';

.project {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  height: 130px;
  padding: 20px;
  cursor: pointer;
  border: 1px solid $globe-passive-color;
  border-radius: 8px;
  gap: 8px;

  &__member {
    color: $globus-background-primary-text-color;
  }

  .project-module {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &__icon {
      position: relative;
      border: 1px solid white;
      border-radius: 50%;
    }
  }

  &:hover {
    border: 1px solid white;
    background: $globus-background-primary-color;
  }

  &-active {
    border-color: $globe-active-color;

    &:hover {
      border: 1px solid $globe-active-color;
    }
  }

  &-body {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__title {
      font-size: 14px;
      font-weight: 500;
      display: inline-block;
      overflow: hidden;
      width: 200px;
      text-align: start;
      white-space: nowrap;
      text-decoration: none;
      text-overflow: ellipsis;
      color: $text-01;
    }

    &__button {
      z-index: 100;
      width: 14px;
      height: 14px;
    }

    &-menu {
      position: absolute;
      z-index: 2000;
      top: 0;
      left: 95px;
      width: 210px;
      padding: 6px;
      border-radius: 8px;
      background: #FFFFFF;
      box-shadow: 1px 2px 10px 0 #131E2726;

      &__item {
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 40px;
        padding-left: 8px;
        border-radius: 8px;
        gap: 4px;

        &:hover {
          background: $hover-active-color;
        }
      }
    }
  }
}
</style>