<script setup>
import {defineModel, ref} from "vue";
import PasswordVisibleIcon from "@/assets/icons/PasswordVisibleIcon.vue";
import PasswordUnvisibleIcon from "@/assets/icons/PasswordUnvisibleIcon.vue";

defineProps({
  label: {
    type: String,
    required: true,
  }
});

const model = defineModel();
const isShowPassword = ref(true);

const inputRef = ref(null);

const changePasswordVisible = () => {
  inputRef.value.type = isShowPassword.value ? "text" : "password";
  isShowPassword.value = !isShowPassword.value; 
};

</script>

<template>
  <div class="input-wrapper">
    <label for="password-input">{{ label }}</label>
    <div class="input">
      <input
        id="password-input"
        ref="inputRef"
        v-model="model"
        type="password"
        autocomplete="true"
      >
      <PasswordUnvisibleIcon
        v-if="isShowPassword"

        click="changePasswordVisible"
        class="icon"
        @click="changePasswordVisible"
      />
      <PasswordVisibleIcon
        v-else
        class="icon"
        @click="changePasswordVisible"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

.input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    color: #212327;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DEDEDE;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
    text-align: left;
    input {
      all: unset;
      width: 90%;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
      -webkit-background-clip: text;
      -webkit-text-fill-color: #212327;
      box-shadow: inset 0 0 20px 20px #FFFFFF;
    }
  }

  .icon {
    cursor: pointer;
    user-select: none;

    width: 16px;
    height: 16px;
  }

}



</style>